var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Pill Themes"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeThemes) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use class ")]), _c('code', [_vm._v(".nav-pill-{color-name}")]), _c('span', [_vm._v(" with ")]), _c('code', [_vm._v("<b-tabs>")]), _c('span', [_vm._v(" class to apply color according to your choice.")])]), _c('h6', [_vm._v("Primary")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "nav-pill-primary"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Active",
      "active": ""
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  })], 1), _c('h6', [_vm._v("Secondary")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "nav-pill-secondary"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Active",
      "active": ""
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  })], 1), _c('h6', [_vm._v("Success")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "nav-pill-success"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Active",
      "active": ""
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  })], 1), _c('h6', [_vm._v("Danger")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "nav-pill-danger"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Active",
      "active": ""
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  })], 1), _c('h6', [_vm._v("Warning")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "nav-pill-warning"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Active",
      "active": ""
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  })], 1), _c('h6', [_vm._v("Info")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "nav-pill-info"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Active",
      "active": ""
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Link"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }