var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pill-basic')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pill-vertical')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pill-filled')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pill-justified')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('pill-alignment')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('pill-themes')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }