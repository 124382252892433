var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Alignments"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAlignment) + " ")];
      },
      proxy: true
    }])
  }, [_c('h6', {
    staticClass: "mb-2"
  }, [_vm._v(" Left ")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "align": "left"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Home",
      "active": ""
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[0]['home']) + " ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Profile"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[1]['profile']) + " ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.tabData[2]['disabled']) + " ")]), _c('b-tab', {
    attrs: {
      "title": "About"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[3]['about']) + " ")])], 1)], 1), _c('h6', {
    staticClass: "mt-2 mb-2"
  }, [_vm._v(" Center ")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Home",
      "active": ""
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[0]['home']) + " ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Profile"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[1]['profile']) + " ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.tabData[2]['disabled']) + " ")]), _c('b-tab', {
    attrs: {
      "title": "About"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[3]['about']) + " ")])], 1)], 1), _c('h5', {
    staticClass: "mt-2 mb-2"
  }, [_vm._v(" Right ")]), _c('b-tabs', {
    attrs: {
      "pills": "",
      "align": "right"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Home",
      "active": ""
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[0]['home']) + " ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Profile"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[1]['profile']) + " ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.tabData[2]['disabled']) + " ")]), _c('b-tab', {
    attrs: {
      "title": "About"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.tabData[3]['about']) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }