var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Vertically Stacked Pills"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVertical) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "vertical": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Pill 1",
      "active": ""
    }
  }, [_c('b-card-text', [_vm._v(" Candy canes donut chupa chups candy canes lemon drops oat cake wafer. Cotton candy candy canes marzipan carrot cake. Sesame snaps lemon drops candy marzipan donut brownie tootsie roll. Icing croissant bonbon biscuit gummi bears. Bear claw donut sesame snaps bear claw liquorice jelly-o bear claw carrot cake. Icing croissant bonbon biscuit gummi bears. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Pill 2"
    }
  }, [_c('b-card-text', [_vm._v(" Pudding candy canes sugar plum cookie chocolate cake powder croissant. Carrot cake tiramisu danish candy cake muffin croissant tart dessert. Tiramisu caramels candy canes chocolate cake sweet roll liquorice icing cupcake. Sesame snaps wafer marshmallow danish dragée candy muffin jelly beans tootsie roll. Jelly beans oat cake chocolate cake tiramisu sweet. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Pill 3"
    }
  }, [_c('b-card-text', [_vm._v(" Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake cookie chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder tootsie roll cake. Macaroon brownie lemon drops croissant marzipan sweet roll macaroon lollipop. Danish fruitcake bonbon bear claw gummi bears apple pie. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Disabled",
      "disabled": ""
    }
  }, [_c('b-card-text', [_vm._v("Macaroon brownie lemon drops croissant marzipan sweet roll macaroon lollipop.")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }